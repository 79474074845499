import { type FC, Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useAuth, useSessionFormPersist } from '@lib/hooks'
import {
  type AddressLookupStore,
  type Cause,
  fixTipValue,
  getTransactionFeeToolTipText,
  PaymentDetails,
  type PaymentFormData,
  PaymentType,
  type ScheduleResponse,
  Tip,
  tipValues,
  useCheckoutPlan,
  useSchedulePayment,
  Variant, MTDtracking, getTipAmount,
  type DayBreakdown, getDayBreakdown, getTotalFees, calculateTip,
  AutoEnroll,
  scrollToHash,
  ScrollHash, ActiveCampaign,
  FridayGiving
} from '@shamaazi/mytennights'
import { Choice, displayCurrency, getCurrencySymbol } from '@lib/services'
import { Button, CreditCard, EmailInput, InputWithRef, SelectWithRef } from '@lib/components'
import { CheckoutProgress, CheckoutStep } from '~/components/CheckoutProgress'

import { Card } from '~/components/Card'
import { MytennightsTestId, PaymentFormTestId } from '@lib/testing'

export const Payment: FC<{ addressLookupStore: AddressLookupStore }> = ({ addressLookupStore }) => {
  const [showAll, setShowAll] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [autoEnrolError, setAutoEnrolError] = useState(false)

  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const queryClient = useQueryClient()
  const { plan, setPlan, resetPlan } = useCheckoutPlan()

  const form = useForm<PaymentFormData>({
    defaultValues: {
      campaign: ActiveCampaign.myTenDays,
      addressLine1: '',
      country: plan.charity.country,
      email: user?.email ?? '',
      firstName: '',
      giftAid: false,
      taxReceipt: false,
      lastName: '',
      postalCode: '',
      tipPercent: Tip.TenPercent,
      emailOptIn: false,
      charityEmailOptIn: false,
      paymentType: PaymentType.googlePay
    }
  })
  const [tipPercent, tipValue] = form.watch(['tipPercent', 'tipValue'])

  const tipAmount = getTipAmount(plan.causes, tipPercent, fixTipValue(tipValue))
  const totalFees = getTotalFees(plan, tipAmount)
  const [fGError, setFGError] = useState('')

  const onSuccessfulPayment = (scheduleResponse: ScheduleResponse, formData: PaymentFormData): void => {
    MTDtracking.paymentSuccessful(formData.email, Boolean(user), plan, scheduleResponse, tipAmount, totalFees, formData)
    queryClient.invalidateQueries('schedules').catch(() => { })

    // setSharePageDetails is not saving the share details into the session storage at the moment
    // this needs to be looked into, but this fixes the problem for now.
    window.sessionStorage.setItem('share_page_details', JSON.stringify({
      email: scheduleResponse.email,
      shareCode: scheduleResponse.shareCode,
      matchedAmount: scheduleResponse.matchedAmount,
      total: scheduleResponse.total,
      matchPotBalance: scheduleResponse.matchPotBalance,
      currency: plan.charity.currency,
      firstName: formData.firstName
    }))
    navigate('/share')
    resetPlan()
    resetForm()
  }

  const { paymentRequest: phonePaymentRequest, submit, isLoading, error: paymentError } = useSchedulePayment(onSuccessfulPayment, form.getValues())
  const payByPhone = phonePaymentRequest && form.getValues('paymentType') !== PaymentType.card

  const resetForm = useSessionFormPersist('checkout_payment', form.watch, form.setValue)

  const causeTotal = (Object.values(plan.causes) ?? []).reduce((acc, v) => acc + v, 0)
  const tip = getTipAmount(plan.causes, tipPercent, fixTipValue(tipValue))
  const fees: number = getTotalFees(plan, tip)
  const total = causeTotal + fees + tip

  const causeBreakdown = plan.charity.causes.map((c: Cause) => {
    const amount = plan.causes[c.cause_id] ?? 0
    return <Fragment key={c.cause_id}>
      <p>{c.title}</p>
      <p className="text-end">{displayCurrency({ amount, currency: plan.charity.currency })}</p>
    </Fragment>
  })

  const dayBreakdown = getDayBreakdown(plan, tip)
  const dailyBreakdown = dayBreakdown.map((d: DayBreakdown) => {
    return <Fragment key={d.number}>
      <p>Day {d.number} ({d.date.toLocaleString(DateTime.DATE_MED)})</p>
      <p className="text-end">{displayCurrency({ amount: d.amount, currency: plan.charity.currency })}</p>
    </Fragment>
  })

  const onToggleFees = (): void => {
    setPlan({ ...plan, paymentDetails: { ...plan.paymentDetails, payFees: !plan.paymentDetails.payFees } })
  }

  useEffect(() => {
    if (phonePaymentRequest !== null) {
      phonePaymentRequest.update({
        currency: plan?.charity?.currency,
        total: {
          label: 'MyTenDays total amount',
          amount: total
        }
      })
    }
  }, [total, phonePaymentRequest, plan?.charity?.currency])

  const submitData = (data: PaymentFormData): void => {
    autoEnrollandFGCheck()
    if (!plan.autoEnroll) {
      return
    }
    submit(data)
  }

  const autoEnrollandFGCheck = (): void => {
    if (!plan.autoEnroll) {
      setAutoEnrolError(true)
      scrollToHash(ScrollHash.autoEnroll)
    }
    if (!plan.fridayGivingOptIn) {
      setFGError('Please let us know if you would be interested in Friday Giving')
      scrollToHash(ScrollHash.fridayGiving)
    }
    if (plan.fridayGivingOptIn === Choice.yes && plan.fridayGiving < 100) {
      setFGError('Please enter a valid amount for your Friday Giving')
      scrollToHash(ScrollHash.fridayGiving)
    }
  }

  return <main>
    <CheckoutProgress currentStep={CheckoutStep.Payment} />
    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
    <form className="max-w-5xl px-4 mx-auto my-6 font-medium" onSubmit={form.handleSubmit((data) => submitData(data))}>
      <h1 className="mb-8 text-3xl font-bold md:mb-12 text-mtd-blue-900">Payment</h1>
      <div className="mx-auto lg:grid lg:gap-x-10 lg:grid-cols-fr-auto">
        <div>
          <Card>
            <p>Your tip keeps us going! Help us help more people catch the blessed days.</p>
            <div className="items-center justify-between mt-6 md:flex">
              <span>Thank you for tipping us:</span>
              <SelectWithRef
                aria-label="Tip percent"
                data-test-id={PaymentFormTestId.tipDropdown}
                className="w-full pr-20 font-medium"
                variant="mtd"
                {...form.register('tipPercent', { required: true })}>
                {Object.keys(tipValues).map((tipPercentage) => {
                  const tipValue = tipValues[tipPercentage]
                  if (!tipValue) return <option key={tipPercentage} value={tipPercentage}>Other</option>
                  return <option key={tipPercentage} value={tipPercentage}>{tipValue}% ({displayCurrency({
                    amount: calculateTip(plan.causes, tipValue),
                    currency: plan.charity.currency
                  })})</option>
                })}
              </SelectWithRef>
            </div>
            <p className="mt-3 text-xs text-right">Tips are taken along with your first payment</p>
            <div className={`flex justify-end mt-3 ${tipPercent === Tip.Other ? '' : 'hidden'}`}>
              <div className="relative">
                <span className="absolute transform -translate-y-1/2 pointer-events-none left-4 top-2/4">
                  {getCurrencySymbol(plan.charity.currency)}
                </span>
                <InputWithRef
                  variant="mtd"
                  className="text-right w-44"
                  aria-label="Tip amount"
                  data-test-id={PaymentFormTestId.tipAmount}
                  placeholder="0.00"
                  step="0.01"
                  type="number"
                  invalid={form.formState.errors.tipValue !== undefined}
                  {...form.register('tipValue', {
                    validate: {
                      max: (v) => (fixTipValue(v) <= 999999) || tipPercent !== Tip.Other || 'Sorry, we don\'t accept tips this large!',
                      min: (v) => (fixTipValue(v) >= 0) || tipPercent !== Tip.Other || 'Sorry, we aren\'t able to accept this tip'
                    },
                    setValueAs: (v) => fixTipValue(parseFloat(v))
                  })}
                  inputMode="decimal"
                  size={1} />
              </div>
            </div>
            {tipPercent === Tip.Other && <div className="mt-2 text-xs text-right text-mtd-red min-h-xs">{form.formState.errors?.tipValue?.message ?? ''}</div>}
          </Card>
          <section className="border rounded shadow-lg border-mtd-gray-100 text-mtd-gray-700 mt-3">
            <AutoEnroll error={autoEnrolError} setError={setAutoEnrolError} platform={Variant.mtd} />
          </section>
          <section className=" bg-white border rounded shadow-lg border-mtd-gray-100 text-mtd-gray-700 mt-3">
            <FridayGiving fGError={fGError} setFGError={setFGError} />
          </section>

          <Card className="mt-3">
            <div className="grid gap-x-10 sm:gap-y-5 sm:grid-cols-auto-fr">
              <label htmlFor="email" className="mt-3 w-44">Email Address</label>
              <div className="flex flex-col">
                <EmailInput variant="mtd"
                  data-test-id={PaymentFormTestId.email}
                  register={form.register} requiredEmail={user?.email} disabled={user !== null} errors={form.formState.errors.email}
                  onBlur={() => MTDtracking.emailEntered(form.getValues('email'), Boolean(user),
                    plan)} />
                <p className="mt-2 text-xs text-right">We&apos;ll email you nightly receipts</p>
                {user !== null && <p className="mt-2 text-xs text-right">
                  {/* eslint-disable-next-line @typescript-eslint/no-floating-promises */}
                  If you wish to use another email address please <Link className="underline text-mtd-blue" to="#" onClick={() => { logout() }}>log
                    out</Link> first
                </p>}
                <p className="mt-1 text-xs text-right text-mtd-red min-h-xs">{form.formState.errors.email?.message ?? ''}</p>
              </div>
            </div>
            <PaymentDetails form={form} addressLookupStore={addressLookupStore} variant={Variant.mtd} tracking={MTDtracking} />
          </Card>
        </div>

        <section className="mt-3 lg:mt-0 lg:w-88">
          <div className="flex flex-col gap-4 p-6 bg-white border rounded shadow-lg text-mtd-gray-700 border-mtd-gray-100">
            <h2 className="text-lg font-bold text-mtd-blue-900">Your donation</h2>
            <p className="font-bold">{plan.charity.charity_name}</p>
            <hr />
            <div className="grid gap-y-4 grid-cols-fr-auto">
              <p>Donation Total</p>
              <p className="text-end">{displayCurrency({ amount: causeTotal, currency: plan.charity.currency })}</p>
              <p>My Ten Days Tip</p>
              <p className="text-end" data-test-id={PaymentFormTestId.tipField}>{displayCurrency({ amount: tip, currency: plan.charity.currency })}</p>
              <div className="relative flex flex-wrap gap-2"><span>Transaction Fee</span>
                <div
                  className="w-6 h-6 text-center text-white rounded-full cursor-pointer bg-mtd-blue"
                  tabIndex={-1}
                  onFocus={() => setShowTooltip(true)}
                  onBlur={() => setShowTooltip(false)}
                >?
                </div>
                <u className="w-20 underline cursor-pointer" onClick={() => onToggleFees()}>
                  {plan.paymentDetails.payFees ? 'Remove' : 'Add'}
                </u>
                {showTooltip && <div className="absolute p-4 text-xs bg-white border rounded shadow-lg border-mtd-gray-200 bottom-full w-60 left-6">
                  {getTransactionFeeToolTipText()}
                </div>}
              </div>
              <p className="text-end">{displayCurrency({ amount: fees, currency: plan.charity.currency })}</p>
              <hr className="col-span-2" />
              <p><strong>Total</strong></p>
              <p className="text-end"><strong>{displayCurrency({ amount: total, currency: plan.charity.currency })}</strong></p>
            </div>
            <hr />
            {!showAll && <div className="flex justify-end">
              <p className="underline cursor-pointer" onClick={() => setShowAll(true)}>
                See Breakdown
              </p>
            </div>}

            {showAll && <>
              <p><strong>Causes breakdown</strong></p>
              <div className="grid gap-3 grid-cols-fr-auto">
                {causeBreakdown}
              </div>
              <hr />
              <p><strong>Daily breakdown</strong></p>
              <div className="grid gap-3 grid-cols-fr-auto">
                {dailyBreakdown}
              </div>
              <hr />
              <div className="flex justify-end">
                <p className="underline cursor-pointer" onClick={() => setShowAll(false)}>See Less</p>
              </div>
            </>}
            {!payByPhone && <Button
              data-test-id={MytennightsTestId.submitButton}
              variant="mtd-primary"
              disabled={isLoading}
              loading={isLoading}
              onClick={autoEnrollandFGCheck}
            >
              DONATE WITH CARD {<CreditCard className="inline align-text-bottom" />}</Button>
            }
            {paymentError && <div data-test-id={PaymentFormTestId.errorText}
              className="mt-3 text-sm text-right text-mtn-red">
              {paymentError instanceof Error ? paymentError.message : 'Sorry, we weren\'t able to create your donation schedule'}
            </div>}
            <p className="text-xs text-mtd-gray-600">
              By continuing, you agree with the MyTenDays terms and privacy policy.
              You also agree with {plan.charity.charity_name}&apos;s <a href={plan.charity.privacy_policy_url}>privacy policy</a>.
              You also authorise MyTenDays to send instructions to the financial
              institution that issued your card to take payments from your card account
              in accordance with the agreement with you.
            </p>
          </div>
        </section>
      </div>
    </form>
  </main>
}
